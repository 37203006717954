* {
    box-sizing: border-box;

    -webkit-backface-visibility: hidden;

    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;

    &::before,
    &::after {
        box-sizing: border-box;
    }
}

button,
input,
textarea {
    background: none;
    border: 0;
    border-radius: 0;
    outline: none;
}

a {
    color: inherit;
    text-decoration: none;
}
#app.active {
    font-size: $font-size-base;

    @media all and (max-width: 1350px) {
        font-size: $font-size-base - 2px;
    }
    @media all and (max-width: 1300px) {
        font-size: $font-size-base - 3px;
    }
    @media all and (max-width: 1024px) {
        font-size: $font-size-base - 5px;
    }
    @include mobile_or_P{
        font-size: 4.375vmin;
    }
    @media screen and (width: 768px) {
        font-size: 3vmin;
    }
}

.forDesktop{ // десктоп или горизонтальный планшет
    @media screen and (max-width: 1000px){
        display: none !important;
    }
}
.forMobile_or_p{ // мобилка или портрентая ориентация
    @media screen and (min-width: 1001px){
        display: none !important;
    }
}
