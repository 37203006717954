/* This stylesheet generated by Transfonter (https://transfonter.org) on February 25, 2018 4:00 PM */

@font-face {
    font-family: 'Roboto';
    font-weight: 500;
    font-style: italic;

    src: url('@/assets/fonts/Roboto-MediumItalic.eot');
    src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url('@/assets/fonts/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('@/assets/fonts/Roboto-MediumItalic.woff') format('woff'),
    url('@/assets/fonts/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: normal;
    font-style: italic;

    src: url('@/assets/fonts/Roboto-Italic.eot');
    src: local('Roboto Italic'), local('Roboto-Italic'),
    url('@/assets/fonts/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
    url('@/assets/fonts/Roboto-Italic.woff') format('woff'),
    url('@/assets/fonts/Roboto-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: bold;
    font-style: normal;

    src: url('@/assets/fonts/Roboto-Bold.eot');
    src: local('Roboto Bold'), local('Roboto-Bold'),
    url('@/assets/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
    url('@/assets/fonts/Roboto-Bold.woff') format('woff'),
    url('@/assets/fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: normal;
    font-style: normal;

    src: url('@/assets/fonts/Roboto-Regular.eot');
    src: local('Roboto'), local('Roboto-Regular'),
    url('@/assets/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('@/assets/fonts/Roboto-Regular.woff') format('woff'),
    url('@/assets/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 500;
    font-style: normal;

    src: url('@/assets/fonts/Roboto-Medium.eot');
    src: local('Roboto Medium'), local('Roboto-Medium'),
    url('@/assets/fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
    url('@/assets/fonts/Roboto-Medium.woff') format('woff'),
    url('@/assets/fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: bold;
    font-style: italic;

    src: url('@/assets/fonts/Roboto-BoldItalic.eot');
    src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
    url('@/assets/fonts/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('@/assets/fonts/Roboto-BoldItalic.woff') format('woff'),
    url('@/assets/fonts/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 100;
    font-style: italic;

    src: url('@/assets/fonts/Roboto-ThinItalic.eot');
    src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url('@/assets/fonts/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('@/assets/fonts/Roboto-ThinItalic.woff') format('woff'),
    url('@/assets/fonts/Roboto-ThinItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 900;
    font-style: normal;

    src: url('@/assets/fonts/Roboto-Black.eot');
    src: local('Roboto Black'), local('Roboto-Black'),
    url('@/assets/fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
    url('@/assets/fonts/Roboto-Black.woff') format('woff'),
    url('@/assets/fonts/Roboto-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 300;
    font-style: normal;

    src: url('@/assets/fonts/Roboto-Light.eot');
    src: local('Roboto Light'), local('Roboto-Light'),
    url('@/assets/fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
    url('@/assets/fonts/Roboto-Light.woff') format('woff'),
    url('@/assets/fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 300;
    font-style: italic;

    src: url('@/assets/fonts/Roboto-LightItalic.eot');
    src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url('@/assets/fonts/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('@/assets/fonts/Roboto-LightItalic.woff') format('woff'),
    url('@/assets/fonts/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 900;
    font-style: italic;

    src: url('@/assets/fonts/Roboto-BlackItalic.eot');
    src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
    url('@/assets/fonts/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('@/assets/fonts/Roboto-BlackItalic.woff') format('woff'),
    url('@/assets/fonts/Roboto-BlackItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 100;
    font-style: normal;

    src: url('@/assets/fonts/Roboto-Thin.eot');
    src: local('Roboto Thin'), local('Roboto-Thin'),
    url('@/assets/fonts/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
    url('@/assets/fonts/Roboto-Thin.woff') format('woff'),
    url('@/assets/fonts/Roboto-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'DearType';
    font-weight: 700;
    font-style: normal;

    src: url('@/assets/fonts/dear-type/DearType-LifehackSansBold.otf');
}
@font-face {
    font-family: 'DearType';
    font-weight: 500;
    font-style: normal;

    src: url('@/assets/fonts/dear-type/DearType-LifehackSansMedium.otf');
}
