@mixin fade-in($dur: 0.4, $del: 0, $scale: 0.8) {
  transition: opacity #{$dur}s #{$del}s, transform #{$dur}s #{$del}s;
  &:not(.animated) {
    transform: scale(#{$scale}) !important;
    opacity: 0;
  }
}
@mixin fade-in-center($dur: 0.4, $del: 0, $scale: 0.8) {
  transition: opacity #{$dur}s #{$del}s, transform #{$dur}s #{$del}s;
  &:not(.animated) {
    transform: scale(#{$scale}) translateX(-50%);
    opacity: 0;
  }
}
@mixin fade($dur: 0.4, $del: 0) {
  transition: opacity #{$dur}s #{$del}s;
  &:not(.animated) {
    opacity: 0;
  }
}
@mixin fade-right($dur: 0.6, $del: 0) {
  transition: opacity #{$dur - $dur/2}s #{$del}s, transform #{$dur}s #{$del}s;
  transition-timing-function: ease-in-out;
  &:not(.animated) {
    transform: translateX(20px);
    opacity: 0;
  }
}


.chipx {
  @include fade(0.3, 0.6);
}

.intro {
  .gradient1 {
    @include fade-in(0.4, 0);
    transform-origin: top left;
  }
  .gradient2 {
    @include fade-in(0.4, 0.1);
    transform-origin: top right;
  }
  .intro__title {
    @include fade(0.3, 0.5)
  }
  .pack1 {
    transition: opacity 0.3s 0.5s, transform 0.3s 0.5s;
    &:not(.animated) {
      transform: rotate(-20deg) !important;
      opacity: 0;
    }
  }
  .pack2 {
    transition: opacity 0.3s 0.7s, transform 0.3s 0.7s;
    &:not(.animated) {
      transform: rotate(20deg) !important;
      opacity: 0;
    }
  }
  .line1 {
    transition: opacity 0.3s 0.8s, transform 0.3s 0.8s;
    &:not(.animated) {
      transform: translate(-20px, -20px) !important;
      opacity: 0;
    }
  }
  .line2 {
    transition: opacity 0.3s 0.9s, transform 0.3s 0.9s;
    &:not(.animated) {
      transform: translate(20px, -20px) !important;
      opacity: 0;
    }
  }
  &__packs-arrow {
    animation: 3s linear 1s normal none infinite running pulse-opacity;
    img {
      @include fade(0.4, 1.2);
    }
  }
  p.wow {
    @include fade-right(0.5, 1.4);
  }
  .intro-places {
    .chip {
      @include fade(0.4, 0.5);
    }

    .arrow1, .arrow2 {
      transition: opacity 0.3s 0s, transform 0.3s 0s;
    }

    &:not(.animated) {
      .arrow1, .arrow2 {
        transform: scale(0.8);
        opacity: 0;
      }
    }

    &.animated {
      .arrow1, .arrow2 {
        animation: 1.2s ease-in-out 0s normal none running trambling-animation;
      }
    }

    .in1, .in2, .gradient {
      @include fade-in(0.4, 0.1);
    }
  }
}

.rules {
  .chip {
    @include fade();
  }
  .chip2 {
    @include fade(0.4, 0.8);
  }

  &-content {
    &:after {
      transition: opacity 0.5s;
    }
    &:not(.animated) {
      &:after {
        opacity: 0;
      }
    }
  }

  &__title {
    @include fade();
    .arrow {
      transform-origin: top center;
      transition: opacity 0.5s, transform 0.5s;
    }
    &:not(.animated) {
      .arrow {
        transform: rotate(-90deg);
        opacity: 0;
      }
    }
  }

  &__rule {
    @include fade-right(0.4);
  }
}

.mix {
  .gradient1 {
    @include fade-in-center();
  }

  .drop-container {
    &.error {
      transform-origin: center center;

      animation: 1.2s ease-in-out 0s normal none running trambling-animation;
    }
  }
  .result-container {
    .gradient {
      animation: 3s ease-in-out 0s normal none infinite running pulse-opacity;
    }
    .mix__result {
      .float {
        animation: 2s ease-in-out 0s normal none infinite running float;
        &:nth-of-type(2) {
          animation-delay: 0.3s;
        }
        &:nth-of-type(3) {
          animation-delay: 0.2s;
        }
        &:nth-of-type(4) {
          animation-delay: 0.4s;
        }
      }
    }
  }
}

.winners {
  &:before {
    transition: opacity 0.5s;
  }
  .arrow {
    transition: opacity 0.3s 0s, transform 0.3s 0s;
  }

  .arrow.aos-animate {
    animation: 1.2s ease-in-out 0s normal none running trambling-animation;
  }
  &:not(.animated) {
    &:before {
      opacity: 0;
    }
  }
  .chip {
    @include fade(0.5, 0.5);
  }
  p.wow {
    @include fade-right();
  }
  .winner {
    @for $i from 5 through 9 {
      &:nth-of-type(#{$i}) {
        @include fade-right(0.5, #{$i/10});
      }
    }
  }
}

.ruffle {
  .gradient, &__rules {
    @include fade-in();
  }
  &__title {
    @include fade();
  }
  .chip {
    @include fade(0.5, 0.5)
  }
  &__step, p.wow {
    @include fade-right();
  }

  .hash-tag1, .hash-tag2 {
    &.aos-animate {
      animation: 1.2s ease-in-out 0.4s normal none running trambling-animation-low;
    }
  }
}

.try {
  .chip {
    @include fade(0.5, 0.5);
  }

  &__title, p.wow {
    @include fade-right();
  }
  &__icons {
    @include fade-in()
  }
  .pulse.wow {
    @include fade-in-center();

    transition: 0.2s;
  }
}


@keyframes trambling-animation {
  0%, 50%, 100% {
    transform: translate3d(0,0,0) rotate(0deg);
  }
  10%, 30% {
    transform: translate3d(0,0,0)  rotate(-10deg);
  }
  20%, 40% {
    transform: translate3d(0,0,0)  rotate(10deg);
  }
}
@keyframes trambling-animation-low {
  0%, 50%, 100% {
    transform: translate3d(0,0,0)  rotate3d(0,0,0, 5deg);
  }
  10%, 30% {
    transform: translate3d(0,0,0)  rotate(-5deg);
  }
  20%, 40% {
    transform: translate3d(0,0,0)  rotate(5deg);
  }
}

.pulse {
  //transform: translate3d(0,0,0);

  //animation: 4s linear 0s normal none infinite running pulse;

 // will-change: transform;
}

@keyframes pulse {
  0%, 100% {
    //opacity: 0.6;
    transform: scale3d(0.95, 0.95, 1);
  }
  70% {
    //opacity: 1;
    transform: scale3d(1.05, 1.05, 1);
  }
}
@keyframes pulse-opacity {
  0%, 100% {
    transform: translateX(-50%) scale3d(0.97, 0.97, 1);
    opacity: 0.7;
  }
  50% {
    transform: translateX(-50%) scale3d(1.03, 1.03, 1);
    opacity: 1;
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}

.checkViewChip {
  &.hidden {
    visibility: hidden;
    &>* {
      transform: none !important;
      visibility: hidden !important;
    }
  }
}

.light {
  $filterColor: rgba(#00D5FF, 0.6);
  //box-shadow: inset 0px 0px 1px $filterColor;

  transform: translate3d(0,0,0);
  filter: drop-shadow(0px 0px 16px $filterColor) drop-shadow(0px 0px 4px $filterColor) drop-shadow(0px 0px 8px $filterColor) drop-shadow(0px 0px 24px $filterColor);
  transition: 0.2s;

  &:hover {
    box-shadow: inset 0px 0px 0px $filterColor;

    filter: drop-shadow(0px 0px 16px $filterColor) drop-shadow(0px 0px 24px $filterColor);
  }
}

.chip {
  will-change: transform;
}
