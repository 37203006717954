.fade-enter {
    opacity: 0 !important;
}
.fade-enter-active {
    transition: 0.3s !important;
}
.fade-leave-active {
    opacity: 0 !important;
    transition: 0.3s !important;
}

.fade-out-in-enter {
    opacity: 0 !important;
}
.fade-out-in-enter-active {
    transition: 0.3s 0.3s !important;
}
.fade-out-in-leave-active {
    opacity: 0 !important;
    transition: 0.3s !important;

    &.swiper-2 {
        height: 0 !important;
        overflow: hidden !important;
    }
}

.fade-up-enter {
    transform: translate3d(0, -0.5rem, 0) !important;
    opacity: 0 !important;
}
.fade-up-enter-active {
    transition: 0.3s !important;
}
.fade-up-leave-active {
    transform: translate3d(0, 0.5rem, 0) !important;
    opacity: 0 !important;
    transition: 0.3s !important;
}
