#app {
  overflow: hidden;

  font-family: Roboto, Arial;
  color: $main-text-color;

  background: black;

  .gradient {
    z-index: -2;

    pointer-events: none;
  }
}

header {
  position: relative;
  z-index: 2;

  display: flex;
  justify-content: center;

  padding: 21px 0;

  background: #000;
  border-radius: 0px 0px 32px 32px;
}

.chip {
  position: absolute;
  top: 24px;
  left: -47px;
  z-index: 1;

  width: 138px;

  &.z-1 {
    z-index: -1;
  }

  &.chipx {
    z-index: 2;
  }
}

.container {
  width: 91%;
  max-width: 328px;
  margin: 0 auto;
}

.intro {
  position: relative;

  &__title {
    position: relative;
    z-index: 1;

    margin: 7px auto;

    img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &__packs {
    position: relative;
    z-index: 1;

    height: 427px;

    img {
      position: absolute;

      &.pack1 {
        top: 105px;
        left: -133px;

        width: 286px;
      }
      &.pack2 {
        top: 99px;
        right: -127px;

        width: 299px;
      }

      &.gradient2 {
        display: none;
      }

      &.line1 {
        top: 280px;
        left: 18px;

        width: 102px;
      }
      &.line2 {
        top: 288px;
        right: 38px;

        width: 88px;
      }
    }

    &-arrow {
      position: absolute;
      top: 261px;
      left: 50%;

      width: 122px;

      transform: translateX(-50%);

      img {
        width: 100%;
      }

      .arrows {
        top: 15px;
        left: 50%;

        width: 67%;

        transform: translateX(-50%);
      }
    }
  }

  br {
    display: none;
  }

  .gradient {
    position: absolute;
    top: -440px;
    left: calc(50% - 170px - 555px);
    z-index: 1 !important;

    width: 1060px;

    pointer-events: none;
  }
  .gradient2 {
    top: -583px;
    right: calc(50% - 170px - 555px);
    left: auto;
  }

  p {
    position: relative;
    z-index: 2;

    margin-bottom: 16px;

    font-size: 16px;
    line-height: 24px;
  }

  &-places {
    position: relative;

    height: 200px;

    img {
      position: absolute;
      z-index: 3;
    }

    .arrow1 {
      top: -12px;
      left: -14px;

      width: 109px;
    }
    .arrow2 {
      top: 94px;
      right: -14px;

      width: 109px;
    }
    .in {
      position: absolute;
      z-index: 10;

      font-family: DearType;
      font-size: 40px;
      line-height: 32px;
      letter-spacing: 0.04em;
      text-shadow: 0px 0px 16px #FF00CC, 0px 0px 4px #FF00CC, 0px 0px 8px #FF00CC, 0px 0px 24px #FF00CC;

      transform: rotate(5deg);

      br {
        display: block;
      }
    }
    .in1 {
      top: 10px;
      right: calc(50% - 139px);
    }
    .in2 {
      top: 102px;
      left: calc(50% - 162px);

      text-shadow: 0px 0px 16px #00D5FF, 0px 0px 4px #00D5FF, 0px 0px 8px #00D5FF, 0px 0px 24px #00D5FF;

      transform: rotate(-5deg);
    }
    .chip {
      top: -195px;
      right: -89px;
      left: auto;
    }
    .gradient {
      z-index: 0;
    }
  }
}

.rules {
  position: relative;

  .chip {
    top: -52px;
    left: -54px;
    z-index: 2;

    width: 163px;

    &.chip2 {
      top: auto;
      bottom: -41px;
      left: -33px;
      z-index: 0;

      width: 140px;
    }
  }

  &-content {
    position: relative;
    z-index: 1;

    height: 700px;
    padding-top: 54px;

    &:after {
      content: '';
      position: absolute;
      top: -336px;
      right: -410px;
      z-index: -1;

      width: 960px;
      height: 149%;

      background: url("@/assets/img/rules/r-bg.png") no-repeat top left / cover;

      pointer-events: none;
    }
  }

  &__title {
    position: relative;

    margin-bottom: 44px;
    margin-left: 10px;
    .arrow {
      position: absolute;
      top: 33px;
      left: 255px;
    }
  }

  &__rule {
    display: flex;
    align-items: flex-start;

    margin-bottom: 3px;

    font-size: 16px;
    line-height: 24px;
    font-weight: 700;

    img {
      margin-top: -35px;
      margin-right: -9px;
    }

    span {
      color: $blue;
    }

    p {
      margin-top: -3px;
    }
  }
}

.mix {
  height: 650px;

  --packHeight: 160px;

  .container {
    position: relative;
    z-index: 1;

    width: 100%;
    max-width: 380px;
  }

  &__title {
    position: relative;

    display: flex;
    justify-content: center;

    width: 79%;
    height: 110px;
    margin: 4px auto;

    img {
      position: absolute;
    }

    &-res {
      height: 50px;
    }

    &-result {
      position: relative;
      z-index: 1;

      width: calc(100% - 6px);
      margin-top: 14px;
      margin-left: 3px;

      font-family: DearType;
      font-size: 32px;
      line-height: 1;
      font-weight: 500;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 1.3px;

      transform: rotate(-3deg);

      span {
        color: $blue;
        word-spacing: -8px;
      }

      &.back {
        position: absolute;
        top: 0;
        left: 3px;
        z-index: -1;

        width: 100%;
        margin-top: 0;
        margin-left: 0;

        color: #9D1C83;
        span {
          color: #9D1C83;
        }
      }
    }
  }

  h3 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-align: center;
  }


  &__dnd-container {
    position: absolute;
    top: 0;
    left: calc(50% - 50vw);
    z-index: 5;

    display: flex;
    align-items: center;

    width: 100vw;
    height: var(--packHeight);
    margin-right: 18px;

    &.start {
      justify-content: flex-end;
    }

    img {
      height: 100%;
    }

    .smooth-dnd-container {
      width: 30%;
      height: 100%;
      margin: 0 10px;

      pointer-events: none;

      &:not(:nth-of-type(2), :last-of-type) {
        height: 92%;
      }

      &:last-of-type {
        margin:0 !important;
      }

      &[data-disabled="true"] {
        pointer-events: none;
        * {
          pointer-events: none;
        }
      }
    }

    .smooth-dnd-draggable-wrapper {
      width: 100%;
      height: 100%;
      object-fit: contain;

      opacity: 0;

      pointer-events: all;

      &.disabled {
        pointer-events: none;
      }

      &.animated {
        pointer-events: none;
      }

      &.smooth-dnd-ghost {
        z-index: 20;

        height: var(--packHeight) !important;

        transform: scale(1.1);
        opacity: 1;

        animation-name: target;
        animation-duration: 0.5s;
        animation-play-state: running;
        animation-fill-mode: forwards;

        pointer-events: all;

      }
    }
  }

  @keyframes target {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1.1);
    }
  }

  .drop-container {
    position: absolute;
    top: 227px;
    left: 71px;
    z-index: 3;

    height: 125px !important;

    $filterColor: rgba(#00D5FF, 0.6);
    filter: drop-shadow(0px 0px 16px $filterColor)
    drop-shadow(0px 0px 4px $filterColor) drop-shadow(0px 0px 8px $filterColor) drop-shadow(0px 0px 24px $filterColor);

    &>div {
      height: 125px !important;

      transform: none !important;
    }

    &.disabled {
      pointer-events: none;
    }

    img {
      position: relative;
      z-index: 3;

      transform: none !important;
    }

    .question {
      position: absolute;
      top: 5%;
      left: 50%;
      z-index: -2;

      transform: translate(-50%, 0) !important;
    }

    .resImg {
      position: absolute;
      top: 8px;
      left: 10px;
      z-index: 1;

      width: 84px;

      transform: rotate(-10deg) !important;

      img {
        width: 100%;
        object-fit: contain;

        filter: none
      }
    }
  }

  .drop-container2 {
    top: 205px;
    right: 70px;
    left: auto;

    $filterColor: rgba(#FF00CC, 0.6);
    filter: drop-shadow(0px 0px 16px $filterColor)
    drop-shadow(0px 0px 4px $filterColor) drop-shadow(0px 0px 8px $filterColor) drop-shadow(0px 0px 24px $filterColor);

    .resImg {
      top: 9px;
      left: 11px;

      transform: rotate(12deg) !important;
    }
  }

  .drop-container3 {
    top: 256px;
    left: -5px;

    filter: none;

    .resImg {
      z-index: 5;
    }
  }
  .drop-container4 {
    top: 238px;
    right: -5px;
    left: auto;

    filter: none;

    .resImg {
      z-index: 5;
    }
  }

  .swiper {
    position: relative;
    left: calc((50% - 50vw) / 2);

    width: 100vw;
    margin-top: 15px;
    overflow: hidden;

    transform: none !important;

    &:before, &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0%;
      bottom: 0;
      z-index: 2;

      width: 80px;

      background: linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0) 100%);

      pointer-events: none;
    }

    &:before {
      right: auto;
      left: 0%;

      background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }

    img {
      width: 100%;
      height: var(--packHeight);
      object-fit: contain;

      transform-origin: center center;
      transition: transform 0.2s, opacity 0.2s, filter 0.2s;
    }

    .swiper-slide {
      &:not(.swiper-slide-active){
        img {
          transform: scale(0.92);
        }
      }

      .disabled {
        //opacity: 0.4;
        filter: brightness(0.2);
      }
    }

    .swiper-button {
      position: absolute;
      top: 44%;
      left: 25px;
      z-index: 900;

      width: 40px;
      height: 62px;

      background: url("@/assets/img/mix/arrow.svg") no-repeat right center / 40%;

      transform: translateY(-50%);

      $filterColor: rgba(#00D5FF, 0.6);
      filter: drop-shadow(0px 0px 16px $filterColor)
      drop-shadow(0px 0px 4px $filterColor) drop-shadow(0px 0px 8px $filterColor) drop-shadow(0px 0px 24px $filterColor);
      transition: 0.2s;

      &:hover {
        filter: drop-shadow(0px 0px 16px $filterColor) drop-shadow(0px 0px 24px $filterColor);
      }

      &.swiper-button-prev {
        transform: translateY(-50%) rotate(-180deg);
      }

      &.swiper-button-next {
        right: 26px;
        left: auto;
      }
    }

    .swiper-pagination {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 1rem;
      margin-top: 12px;
    }

    .swiper-pagination-bullet {
      width: 5px;
      height: 5px;
      margin: 0 8px;

      background: white;
      border-radius: 50%;

      &.swiper-pagination-bullet-active {
        box-shadow: 0px 0px 16px #00D5FF, 0px 0px 4px #00D5FF, 0px 0px 8px #00D5FF, 0px 0px 24px #00D5FF, inset 0px 0px 1px #00D5FF;

        transform: scale(1.3);
      }
    }
  }

  #giftsSwiper {
    .swiper-slide {
      img {
        transform: scale(0.7);
      }
      &.swiper-slide-active {
        position: relative;
        z-index: 2;
        img {
          position: relative;
          z-index: 2;

          transform: scale(1);
        }
      }
    }
  }

  .gradient {
    position: absolute;
    z-index: -1;
  }
  .gradient1 {
    top: 231px;
    left: 50%;

    transform: translateX(-50%);
  }
  .gradient2 {
    top: 76px;
    left: 50%;

    transform: translateX(-50%);
  }
  .gradient4 {
    top: -37px;
    left: 50%;

    transform: translateX(-50%);
  }

  &__next {
    display: block;

    width: fit-content;
    margin: 165px auto 0;

    &.first {
      margin-top: 310px;
    }
    &.second {
      margin-top: 15px;
    }
  }
  &__twister {
    position: relative;

    &.animate {
      animation: 1.2s ease-in-out 0s normal none infinite running trambling-animation;
    }

    .chips-wrapper {
      position: absolute;
      bottom: 51%;
      left: 50%;
      z-index: 10;

      display: flex;
      justify-content: center;

      width: 180px;
      height: 140px;
      overflow: hidden;

      border-radius: 50%;

      transform: translateX(-50%);

      pointer-events: none;

      .chips {
        animation-name: to-down;
        animation-duration: 1.5s;
        animation-delay: 1s;
        animation-fill-mode: forwards;
      }
      @keyframes to-down {
        from {
          transform: translateY(0);
        }
        to {
          transform: translateY(60%);
        }
      }
    }
  }
  &__back {
    position: absolute;
    right: 10px;
    bottom: 16px;

    display: flex;
    flex-direction: column;
    align-items: center;

    font-weight: 500;
    text-decoration: underline;
    text-shadow: 0px 0px 4px #00D5FF, 0px 0px 20px #00D5FF, 0px 0px 9px #00D5FF;

    img {
      width: 60px;
      margin-bottom: 4px;
    }

    span {
      position: relative;
      z-index: 3;
    }
  }

  &__result {
    .float {
      position: absolute;

      img {
        width: 100%;
      }

      &:nth-of-type(1) {
        bottom: 215px;
        left: 4px;

        width: 156px;

        img {
          transform: rotate(-14deg);
        }
      }
      &:nth-of-type(2) {
        right: 2px;
        bottom: 220px;

        width: 162px;

        img {
          transform: rotate(10deg);
        }
      }
      &:nth-of-type(3) {
        bottom: 172px;
        left: 77px;

        width: 120px;

        //transform: rotate(-7deg);
      }
      &:nth-of-type(4) {
        right: 108px;
        bottom: 282px;

        width: 110px;

        //transform: rotate(38deg);
      }
    }
  }
}

.winners {
  position: relative;
  z-index: 1;

  padding-bottom: 67px;

  &.empty {
    &:before {
      top: -65px;
      right: 50%;

      width: 120%;
      max-width: 450px;
      height: 300px;

      background: url("@/assets/img/winners/bg-w_def.png") no-repeat center center / contain;
    }

    .arrow {
      bottom: 31px;
      left: calc(50% - 190px - 33px);
    }
    .arrow2 {
      right: calc(50% - 188px - 33px);
      bottom: 35px;
      left: auto;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: -305px;
    right: calc(50% + 219px);
    z-index: -1;

    width: 866px;
    height: 776px;

    background: url("@/assets/img/winners/bg-w.png") no-repeat top right / cover;

    transform: translateX(50%);

    pointer-events: none;
  }

  &__title {
    position: relative;

    width: 66%;
    margin: 42px auto 0;

    img {
      position: absolute;
    }
  }

  p {
    margin-bottom: 20px;
    padding-top: 25px;

    line-height: 1.5;
    text-align: center;
  }

  .winner {
    margin-bottom: 8px;

    font-weight: 700;
    text-align: center;
    color: $blue;
  }

  .arrow {
    position: absolute;
    bottom: 92px;
    left: calc(50% - 160px - 33px);

    width: 120px;
  }

  .arrow2 {
    right: calc(50% - 160px - 33px);
    bottom: 85px;
    left: auto;
  }

  .chip1 {
    top: -100px;
    left: -40px;
    z-index: 1;

    width: 118px;
  }
  .chip2 {
    top: -60px;
    right: -120px;
    left: auto;
    z-index: -2;

    width: 178px;
  }
  .chip3 {
    top: 247px;
    left: -2px;
    z-index: -3;

    width: 148px;
  }
}

.ruffle {
  position: relative;
  z-index: 1;

  &__title {
    height: 173px;

    img {
      position: absolute;
    }
  }

  &__step {
    display: flex;
    align-items: flex-start;

    margin-bottom: 20px;

    img {
      margin-top: -35px;
      margin-right: -8px;
      margin-left: -25px;
    }
    p {
      span {
        color: $blue;
      }
    }
  }
  .hash-tag {
    font-family: DearType;
    font-size: 56px;
    font-weight: 700;
    letter-spacing: 0.04em;
    text-shadow: 0px 0px 16px #00D5FF, 0px 0px 4px #00D5FF, 0px 0px 8px #00D5FF, 0px 0px 24px #00D5FF;

    span {
      display: block;

      transform: rotate(-6deg);
    }
  }
  .hash-tag1 {
    will-change: transform;
  }
  .hash-tag2 {
    margin: -6px 88px 20px;

    text-shadow: 0px 0px 16px #FF00CC, 0px 0px 4px #FF00CC, 0px 0px 8px #FF00CC, 0px 0px 24px #FF00CC;

    will-change: transform;
  }

  p {
    line-height: 24px;
  }

  &__rules {
    display: block;

    width: fit-content;
    margin: 15px auto;
  }

  .chip1 {
    top: 86px;
    right: -55px;
    left: auto;
  }
  .chip2 {
    top: 160px;
    right: -38px;
    left: auto;

    width: 117px;
  }
  .chip3 {
    top: 328px;
    left: -94px;
  }
  .chip4 {
    top: 336px;
    right: -68px;
    left: auto;

    width: 160px;
  }
  .gradient {
    position: absolute;

    min-width: 500px;
    min-height: 500px;
  }
  .gradient1 {
    top: -260px;
    z-index: -1;
  }
  .gradient2 {
    top: 110px;
    left: -480px;
    z-index: -1;
  }
  .gradient3 {
    top: auto;
    right: -300px;
    bottom: -435px;
    left: auto;
    z-index: -1;
  }
}

.try {
  position: relative;
  z-index: 1;

  &__title {
    display: block;

    height: auto;
    margin: 59px auto 10px;

    p {
      position: static !important;

      margin-bottom: 20px !important;

      transform: rotate(-3deg) !important;
    }
  }

  &__content {
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      top: -65px;
      left: 50%;
      z-index: -1;

      width: 484px;
      height: 520px;

      background: url("@/assets/img/try/bg.svg") no-repeat top center / cover;

      transform: translateX(-50%);
    }
  }

  &__icons {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    width: 288px;
    margin: 18px auto 26px;
  }

  p {
    margin-bottom: 56px;

    line-height: 24px;
    text-align: center;
  }

   .button {
     display: block;

     width: fit-content;
     margin: 10px auto 15px;
   }

  .chip1 {
    top: -111px;
    left: -51px;

    width: 100px;
  }
  .chip2 {
    top: -55px;
    left: -90px;

    width: 160px;
  }
  .chip3 {
    top: -73px;
    right: -54px;
    left: auto;

    width: 120px;
  }
  .chip4 {
    top: auto;
    right: -56px;
    bottom: 126px;
    left: auto;

    width: 130px;
  }
  .chip5 {
    top: auto;
    right: -63px;
    bottom: 36px;
    left: auto;

    width: 160px;
  }
  .chip6 {
    top: auto;
    bottom: -57px;
    left: -114px;

    width: 190px;
  }

  .gradient {
    position: absolute;

    min-width: 500px;
    min-height: 350px;
  }
  .gradient1 {
    bottom: -300px;
    left: -325px;
    z-index: -1;
  }
  .gradient2 {
    right: -470px;
    bottom: -410px;
    z-index: -1;
  }
}

footer {
  position: relative;
  z-index: 2;

  padding: 39px 9px 16px;

  background: #000;
  border-radius: 32px 32px 0 0;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  img {
    margin-left: 12px;
  }

  a.rules-link {
    display: block;

    padding-top: 27px;

    font-size: 10px;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  p {
    margin-top: 13px;

    font-size: 10px;
    line-height: 16px;

    opacity: 0.56;
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;

  display: flex;
  justify-content: center;

  background: rgba(#000000, 0.6);

  &__scroll {
    position: relative;

    display: flex;

    width: 100vw;
    overflow: auto;
  }

  &__closer {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;

    width: 106px;
  }

  &__content {
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    max-width: 450px;
    height: fit-content;
    margin: 50px auto 0;
    padding: 120px 0 148px;
    overflow: hidden;

    background: url("@/assets/img/modal/bg.png") no-repeat center center / 100%;

    @media screen and (max-width: 340px){
      padding: 60px 0 80px;

      background-size: 120%;
    }

    /* &:before {
       content: '';
       position: absolute;
       top: -18px;
       left: 50%;
       z-index: -1;

       width: 100%;
       min-width: 350px;
       height: 600px;

       background: url("@/assets/img/modal/bg.png") no-repeat center center / contain;

       transform: translateX(-50%);
     }*/
  }

  &__title {
    margin-top: 50px;
  }

  p {
    width: 290px;
    margin-top: 10px;

    font-size: 16px;
    line-height: 24px;
    text-align: center;

    &:nth-of-type(2) {
      width: 250px;
    }

    span {
      font-weight: 700;
      color: $pink;
    }
  }

  &__button {
    margin: 20px auto;
  }

  a {
    width: 190px;
    margin-top: -8px;

    line-height: 24px;
    font-weight: 500;
    text-align: center;
    text-decoration: underline;
  }
}
.share-vk {
  display: none;
}

canvas {
  position: relative;
  z-index: 5000;

  background: black;
}

@media screen and (min-width: 400px) and (max-width: 1000px){
  .mix {
    .swiper {
      left: calc(50% - 200px);

      width: 400px;
    }

    &__dnd-container {
      left: calc(50% - 200px);

      width: 400px;
    }
  }
}

.twister-title {
  position: relative;

  width: 100%;
  min-height: 50px;


  &.blue {
    p {
      &:nth-of-type(1) {
        color: $dark-blue;
      }
    }
  }

  &.fz56 {
    p {
      font-size: 56px;
      line-height: 48px;
      letter-spacing: 1.5px;
    }
  }

  &.fz48 {
    p {
      font-size: 48px;
      line-height: 40px;
      letter-spacing: 1px;
    }
  }

  &.rRotate {
    p {
      transform: translateX(-50%) rotate(3deg);
    }
  }

  p {
    position: absolute;
    top: 0;
    left: 50%;

    width: 100%;
    padding-top: 0;

    font-family: DearType;
    font-size: 64px;
    line-height: 66px;
    font-weight: 700;
    text-align: center;
    color: $dark-pink;
    text-transform: uppercase;
    letter-spacing: 2.5px;

    transform: translateX(-50%) rotate(-3deg);

    span {
      display: block;
    }

    &:nth-of-type(1) {
      top: 2px;
      left: calc(50% + 4px);
    }
    &:nth-of-type(2) {
      top: 4px;
      left: calc(50% + 3px);

      color: #000;
    }
    &:nth-of-type(3) {
      top: 2px;
      left: calc(50%);

      color: #fff;
    }
  }
}



.light-link {
  text-decoration: underline;
  text-shadow: 0px 0px 4px #00D5FF, 0px 0px 20px #00D5FF, 0px 0px 9px #00D5FF;

  transition: text-shadow 0.2s;
  &:hover {
    text-decoration: none;
    text-shadow: 0px 0px 2px #00D5FF, 0px 0px 12px #00D5FF;
  }
}
