@mixin razr1920 {
    @media screen and (max-width: 1920px) {
        @content;
    }
}

@mixin razr1680 {
    @media screen and (max-width: 1680px) {
        @content;
    }
}

@mixin razr1620 {
    @media screen and (max-width: 1620px) {
        @content;
    }
}

@mixin razr1600 {
    @media screen and (max-width: 1600px) {
        @content;
    }
}

@mixin razr1536 {
    @media screen and (max-width: 1536px) {
        @content;
    }
}

@mixin razr1440 {
    @media screen and (max-width: 1440px) {
        @content;
    }
}

@mixin razr1400 {
    @media screen and (max-width: 1400px) {
        @content;
    }
}

@mixin razr1366 {
    @media screen and (max-width: 1366px) {
        @content;
    }
}

@mixin razr1280 {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

@mixin razr1240 {
    @media screen and (max-width: 1240px) {
        @content;
    }
}

@mixin razr1200 {
    @media screen and (max-width: 1200px) {
        @content;
    }
}

@mixin razr1170 {
    @media screen and (max-width: 1170px) {
        @content;
    }
}

@mixin razr1100 {
    @media screen and (max-width: 1100px) {
        @content;
    }
}

@mixin razr1024 {
    @media screen and (max-width: 1024px) {
        @content;
    }
}

@mixin razr1023 {
    @media screen and (max-width: 1023px) {
        @content;
    }
}

@mixin razr960 {
    @media screen and (max-width: 960px) {
        @content;
    }
}

@mixin razr900 {
    @media screen and (max-width: 900px) {
        @content;
    }
}

@mixin razr850 {
    @media screen and (max-width: 850px) {
        @content;
    }
}

@mixin razr768 {
    @media screen and (max-width: 768px) {
        @content;
    }
}

@mixin razr768L {
    @media screen and (max-width: 768px) and (orientation: landscape) {
        @content;
    }
}

@mixin razr768_from {
    @media screen and (min-width: 768px) {
        @content;
    }
}

@mixin razr767 {
    @media screen and (max-width: 767px) {
        @content;
    }
}

@mixin razr767P {
    @media screen and (max-width: 767px) and (orientation: portrait) {
        @content;
    }
}

@mixin razr710 {
    @media screen and (max-width: 710px) {
        @content;
    }
}

@mixin razr680 {
    @media screen and (max-width: 680px) {
        @content;
    }
}

@mixin razr640 {
    @media screen and (max-width: 640px) {
        @content;
    }
}

@mixin razr600 {
    @media screen and (max-width: 600px) {
        @content;
    }
}

@mixin razr570 {
    @media screen and (max-width: 570px) {
        @content;
    }
}

@mixin razr530 {
    @media screen and (max-width: 530px) {
        @content;
    }
}

@mixin razr510 {
    @media screen and (max-width: 510px) {
        @content;
    }
}

@mixin razr480 {
    @media screen and (max-width: 480px) {
        @content;
    }
}

@mixin razr440 {
    @media screen and (max-width: 440px) {
        @content;
    }
}

@mixin razr400 {
    @media screen and (max-width: 400px) {
        @content;
    }
}

@mixin razr420 {
    @media screen and (max-width: 420px) {
        @content;
    }
}

@mixin razr375 {
    @media screen and (max-width: 375px) {
        @content;
    }
}

@mixin razr360 {
    @media screen and (max-width: 360px) {
        @content;
    }
}

@mixin razr320 {
    @media screen and (max-width: 320px) {
        @content;
    }
}

@mixin landscape {
    @media screen and (orientation: landscape) {
        @content;
    }
}

@mixin portrait {
    @media screen and (orientation: portrait) {
        @content;
    }
}

@mixin razr1024L {
    @media screen and (orientation: landscape) and (max-width: 1023px) {
        @content;
    }
}

@mixin razr640H {
    @media screen and (max-height: 640px) {
        @content;
    }
}

@mixin razr570H {
    @media screen and (max-height: 570px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: 768px) and (max-width: 1280px) {
        @content;
    }
}

@mixin laptop {
    @media screen and (min-width: 1281px) and (max-width: 1366px),
        screen and (width: 1280px) and (max-height: 800px) {
        @content;
    }
}

@mixin mobile_or_P {
    @media screen and (max-width: 900px), (orientation: portrait) {
        @content;
    }
}

@mixin desktop_and_L {
    @media screen and (min-width: 901px) and (orientation: landscape) {
        @content;
    }
}
