// Colors

/*
    Название переменной конкретного цвета генерируется через сервис: http://chir.ag/projects/name-that-color/.
    Значение цвета всегда указывается в HEX-формате в нижнем регистре (кроме исключительных случаев).
*/

$main-text-color: #fff;
$blue: #00D5FF;
$pink: #FF00CC;
$dark-pink: #9D1C83;
$dark-blue: #009DBC;
$filterColor: rgba(#00D5FF, 0.6);

// Fonts
$font-size-base: 16px;

