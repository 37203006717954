@media screen and (min-width: 1001px) {
  html {
    font-size: 16px;
    @media screen and (max-width: 1550px) {
      font-size: 14px;
    }
    @media screen and (max-width: 1250px) {
      font-size: 13px;
    }
  }

  #app {
    position: relative;

    .gradients {
      position: absolute;
      top: 0;
      left: 50%;

      min-width: 100%;
      height: 100%;
      overflow: hidden;

      transform: translateX(-50%);

      pointer-events: none;

      img {
        min-width: 100%;
      }

      .gradient1 {
        display: block;

        min-height: rem(1300px);
        margin-bottom: -80rem;
      }

      .gradient2 {
        min-width: 100%;
        min-height: rem(3090px);
        margin-bottom: -40rem;
        @media screen and (min-width: 1921px) {
          max-height: 193.5rem;
        }
      }

      .gradient3 {
        min-width: 100%;
        min-height: rem(3400px);
        margin-bottom: -100rem;
      }

      .gradient4 {
        min-width: 100%;
        min-height: rem(2200px);
        margin-bottom: -70rem;
      }

      .gradient5 {
        min-width: 100%;
        min-height: 5rem;
      }
    }

    .chip {
      display: none;
    }

    .chips {
      position: relative;

      pointer-events: none;

      picture {
        position: absolute;
      }

      &__chip {
        position: absolute;
      }

      .chip1 {
        top: -1.3rem;
        left: calc(50% - 52.6rem);
      }

      .chip2 {
        top: -8.7rem;
        right: 0.6rem;
      }

      .chip3 {
        top: 18.3rem;
        right: 0;
      }

      .chip4 {
        top: 21rem;
        left: -20rem;
      }

      .chip5 {
        top: 53.9rem;
        right: calc(50% - 55rem);
      }

      .chip6 {
        top: 84.9rem;
        right: -21rem;
      }

      .chip7 {
        top: 93rem;
        left: calc(50% - 57rem);
        z-index: 20;
      }

      .chip8 {
        top: 116rem;
        left: -6rem;
      }

      .chip9 {
        top: 118.4rem;
        right: 6rem;
      }

      .chip10 {
        top: 165.4rem;
        left: calc(50% - 69rem);
      }

      .chip11 {
        top: 182.4rem;
        left: calc(50% - 70rem);
        z-index: 90;
      }

      .chip12 {
        top: 204.4rem;
        left: calc(50% - 54rem);
      }

      .chip13 {
        top: 232.4rem;
        left: calc(50% - 50rem);
      }

      .chip14 {
        top: 255.4rem;
        right: -16rem;
        z-index: 20;
      }

      .chip15 {
        top: 280.4rem;
        left: calc(50% - 51rem);
      }

      .chip16 {
        top: 297.4rem;
        right: -8rem;
      }

      .chip17 {
        top: 322.7rem;
        left: -25rem;
        z-index: 2;
      }

      .chip18 {
        top: 362.7rem;
        left: calc(50% - 56rem);
      }

      .chip19 {
        top: 348.7rem;
        left: calc(50% - 43rem);
      }

      .chip20 {
        top: 336.7rem;
        right: -21rem;
        z-index: 10;
      }
    }

    .gifts {
      pointer-events: none;

      .gift {
        position: absolute;
        z-index: 20;

        img {
          animation: 2s ease-in-out 0s normal none infinite running float;
        }

        @for $i from 1 through 9 {
          &:nth-of-type(#{$i}) {
            animation-delay: #{$i/3}s;
          }
        }

        img {
          width: 100%;
        }
      }

      .gift1 {
        top: 42rem;
        right: -4rem;

        width: 21rem;
      }

      .gift2 {
        top: 73.8rem;
        left: -4rem;

        width: 21rem;

        transform: rotate(45deg);
      }

      .gift3 {
        top: 148.4rem;
        right: -4.5rem;
        z-index: 2;

        width: 19rem;

        transform: rotate(28deg);
      }

      .gift4 {
        top: 197rem;
        right: 9rem;
        z-index: 1;

        width: 17.4rem;

        transform: rotate(-24deg);
      }

      .gift5 {
        top: 226.5rem;
        left: 7.8rem;

        width: 25.4rem;

        transform: rotate(-1deg);
      }

      .gift6 {
        top: 278.5rem;
        left: -4rem;
        z-index: 20;

        width: 24rem;

        transform: rotate(25deg);
      }

      .gift7 {
        top: 307.5rem;
        right: 6rem;

        width: 24.4rem;

        transform: rotate(-25deg);
      }

      .gift8 {
        bottom: 66.5rem;
        left: 1rem;

        width: 24.4rem;

        transform: rotate(-20deg);
      }

      .gift9 {
        bottom: 8.6rem;
        left: 8.2rem;

        width: 27.4rem;

        transform: rotate(-20deg);
      }
    }
  }

  header {
    padding: 24px 0;

    img {
      height: 4.5rem;
    }
  }
  .container {
    max-width: rem(620px);
  }
  .intro {
    &__title {
      margin: rem(36px) auto;

      img {
        width: rem(614px);
      }
    }

    &__packs {
      height: rem(425px);
      margin-bottom: 14.5rem;

      &-arrow {
        top: 24.0125rem;
        left: calc(50% + 1.3rem);

        width: 15.4rem;

        cursor: pointer;
        transition: transform 0.2s;

        &:hover {
          animation-play-state: paused;
        }

        .arrows {
          top: 1.7rem;
        }
      }

      img {
        &.pack1 {
          top: 10rem;
          left: -24.4rem;

          width: 40.6rem;

          transform: rotate(-7deg);
        }

        &.pack2 {
          top: 8rem;
          right: -24rem;

          width: 39.9rem;

          transform: rotate(0deg);
        }

        &.line1 {
          top: 14.7rem;
          left: -20.9rem;

          width: 35.6rem;
        }

        &.line2 {
          top: 22.7rem;
          right: -22.4rem;

          width: 35.1rem;
        }
      }
    }

    p {
      margin-bottom: 1.9rem;
      padding: 0 4.4rem;

      font-size: rem(24px);
      line-height: rem(32px);
      font-weight: 300;
      text-align: center;

      &.second {
        padding: 0;
      }

      br {
        display: block;
      }

      span {
        font-weight: 700;
      }
    }

    &-places {
      .arrow1 {
        top: -2.4rem;
        left: -13.5rem;

        width: 16.3rem;

        cursor: pointer;
      }

      .in {
        font-size: rem(100px);
        line-height: rem(80px);

        cursor: pointer;
      }

      .in1 {
        top: 2.3rem;
        right: 4.6rem;
      }

      .arrow2 {
        top: 15rem;
        right: -10.3rem;

        width: 16.5rem;

        cursor: pointer;
      }

      .in2 {
        top: 13.8rem;
        left: 1.2rem;

        width: 50rem;
      }
    }
  }
  .rules {
    margin-top: 11rem;
    padding-top: 5rem;

    .container {
      max-width: 70rem;
    }

    &-content {
      position: relative;

      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;

      height: 48rem;
      padding-top: 16rem;

      &:after {
        top: -29rem;
        right: auto;
        left: 50%;

        width: 83rem;
        height: 53rem;

        background-image: url("@/assets/img/rules/r-bg_d.png");
        background-position: center center;
        background-size: contain;

        transform: translate(-50%, 50%);
      }
    }

    &__title {
      position: absolute;
      top: 5rem;
      right: calc(50% + 1.8rem);

      width: 34.2rem;

      transform: translateX(50%);

      img {
        width: 100%;

        &.arrow {
          top: -2.6rem;
          right: 2rem;
          left: auto;

          width: 11.2rem;
        }

        &.arrow2 {
          top: 15.4rem;
          right: auto;
          left: 2rem;

          transform: rotate(-198deg);
          transform-origin: bottom center;
        }
      }
    }

    &__rule {
      p {
        padding-top: 0.3rem;

        font-size: rem(24);
        line-height: 1.4;
      }

      img {
        margin-top: -2rem;
        margin-right: 0.1rem;
      }

      &:nth-of-type(3) {
        margin-left: 11.5rem;
      }

      &:nth-of-type(4) {
        margin-top: -3.8rem;
        margin-left: 10.4rem;
      }

      &:nth-of-type(5) {
        margin-top: -3.8rem;
        margin-left: 4.5rem;
      }

      &:nth-of-type(6) {
        margin-top: -4rem;
        margin-right: 12rem;
        margin-left: 20.3rem;
      }
    }
  }
  .mix {
    position: relative;
    z-index: 9999;

    min-height: 75rem;
    padding-top: 3.6rem;

    .container {
      max-width: 72rem;
    }

    h3 {
      margin-top: 2.2rem;

      font-size: rem(24px);
    }

    &__title {

      &-result {
        width: 55%;
        margin: 1.3rem auto;

        font-size: rem(72px);
        line-height: 0.9;
      }
    }

    .swiper {
      left: 0;

      width: 100%;
      margin-top: 2.8rem;

      &:before, &:after {
        top: 1.5rem;
        bottom: 5rem;

        width: 18rem;
      }

      &:before {
        left: 0;

      }

      &:after {
        right: 0;
      }

      img {
        height: 18rem;
      }

      .swiper-slide {
        img {
          transform: scale(0.6) !important;
        }

        &.swiper-slide-prev, &.swiper-slide-next {
          img {
            transform: translateX(1.7rem) scale(0.9) !important;
          }
        }

        &.swiper-slide-prev {
          img {
            transform: translateX(-1.7rem) scale(0.9) !important;
          }
        }

        &.swiper-slide-active {
          img {
            transform: none !important;
          }
        }
      }

      .swiper-button {
        top: 39%;
        left: 2.5rem;
        z-index: 52222000;

        width: 3rem;
        height: 5.5rem;

        cursor: pointer;

        &.swiper-button-next {
          right: 2.5rem;
        }
      }

      .swiper-pagination {
        height: 5rem;
        margin-top: 1.8rem;
      }

      .swiper-pagination-bullet {
        width: 9px;
        height: 9px;
        margin: 0 18px;

        cursor: pointer;

        &.swiper-pagination-bullet-active {
          transform: scale(1.8);
        }
      }
    }

    #giftsSwiper {
      .swiper-slide {
        margin: 2rem 0 -2rem;
      }
    }

    &__dnd-container {
      left: calc(50% - 36rem);

      max-width: 72rem;
      height: 18.8rem;

      &.start {
        .smooth-dnd-container {
          margin: 0 !important;
        }
      }

      .smooth-dnd-container {
        width: 20%;

        .smooth-dnd-draggable-wrapper {
          transform: scale(0.6);
        }

        &:nth-of-type(2), &:nth-of-type(4) {
          .smooth-dnd-draggable-wrapper {
            transform: scale(0.9);
          }
        }

        &:nth-of-type(3) {
          //margin: 0 5rem;

          .smooth-dnd-draggable-wrapper {
            transform: scale(1);
          }
        }
      }

      .smooth-dnd-draggable-wrapper {
        cursor: grab;

        &.smooth-dnd-ghost {
          height: 18.8rem !important;

          transform: scale(0.9);
        }
      }
    }

    .drop-container {
      top: 29.6rem;
      left: calc(50% - 12.1rem);

      & > div {
        width: 11.8rem;
        height: 15rem !important;

        img {
          &:nth-of-type(1) {
            width: 100%;
          }
        }
      }

      .question {
        top: 29%;

        width: 6rem;
      }

      .resImg {
        top: 2.1rem;
        left: 2rem;

        width: 7.7rem;
      }

      &.drop-container2 {
        top: 27.6rem;
        right: calc(50% - 12.1rem);
        left: auto;

        .resImg {
          top: 2.1rem;
          left: 2rem;

          width: 7.7rem;
        }
      }

      &.drop-container3 {
        top: 31.9rem;
        left: calc(50% - 24.1rem);

        width: 14rem;

        img {
          width: 100%;
        }

        .resImg {
          top: 2.1rem;
          left: 2rem;

          width: 10rem;
        }
      }

      &.drop-container4 {
        top: 28.9rem;
        left: calc(50% + 13.1rem);

        width: 14rem;

        img {
          width: 100%;
        }

        .resImg {
          top: 2.1rem;
          left: 2rem;

          width: 10rem;
        }
      }
    }

    &__next {
      width: 18rem;
      margin: 19.5rem auto 0;

      cursor: pointer;

      img {
        width: 100%;
      }

      &.first {
        margin-top: 27.3rem;
      }
    }

    &__twister {
      width: 16rem;
      margin: 16.5rem auto 0;

      .chips-wrapper {
        width: 18rem;
        height: 14rem;

        img {
          width: initial;
        }
      }
    }

    &__back {
      top: 2rem;
      right: calc(50% - 36rem);
      bottom: auto;
      z-index: 999999;

      font-size: 18px;

      cursor: pointer;

      span {
        transition: 0.2s;
      }

      &:hover {
        .light {
          box-shadow: inset 0px 0px 0px #00D5FF;

          filter: drop-shadow(0px 0px 16px #00D5FF) drop-shadow(0px 0px 24px #00D5FF);
        }

        span {
          text-decoration: none;
          text-shadow: 0px 0px 2px #00D5FF, 0px 0px 15px #00D5FF, 0px 0px 9px #00D5FF;
        }
      }

      img {
        width: 4rem;
        margin-bottom: 0.5rem;
        margin-left: -0.4rem;
      }
    }

    #giftsSwiper {
      /*& + .mix__dnd-container {
        max-width: 69rem;
      }*/
      .swiper-slide {
        &.swiper-slide-next {
          img {
            transform: translateX(2.5rem) scale(0.9) !important;
          }
        }

        &.swiper-slide-prev {
          img {
            transform: translateX(-2.5rem) scale(0.9) !important;
          }
        }

        &.swiper-slide-active {
          img {
            transform: scale(1.15) !important;
          }
        }
      }
    }

    .result-container {
      .mix__next {
        width: 25rem;
      }
    }

    &__result {
      .float {
        position: absolute;
        z-index: 99;

        pointer-events: none;

        img {
          position: static;

          width: 100%;
        }

        &:nth-of-type(1) {
          bottom: 4.2rem;
          left: -12.5rem;

          width: 33rem;

          img {
            transform: rotate(-13deg);
          }
        }

        &:nth-of-type(2) {
          right: -13.2rem;
          bottom: 4.2rem;

          width: 33rem;


          img {
            transform: rotate(9deg);
          }
        }

        &:nth-of-type(3) {
          bottom: 15.8rem;
          left: calc(50% - 26.2rem);

          width: 23rem;
          max-height: rem(380px);

          img {
            transform: rotate(19deg);
          }
        }

        &:nth-of-type(4) {
          right: calc(50% - 28.2rem);
          bottom: 17.8rem;

          width: 24rem;
          max-height: rem(380px);

          img {
            transform: rotate(14deg);
          }
        }
      }
    }
  }
  .winners {
    &:before {
      top: -5.7rem;
      right: 50%;

      width: 49rem;
      height: 43rem;

      background-image: url("@/assets/img/winners/bg-w_d.png");
      background-position: center center;
      background-size: contain;

      transform: translateX(50%);
    }

    &.empty {
      &:before {
        top: -6rem;

        max-width: 50rem;
        height: 30rem;
      }

      .arrow {
        left: calc(50% - 23rem);

        &.arrow2 {
          right: calc(50% - 22rem);
          bottom: 0;
          left: auto;
        }
      }
    }

    &__title {
      width: 74%;
      margin: 83px auto 0;

      img {
        width: 100%;
      }
    }

    p {
      width: 30rem;
      margin: 0 auto 2.8rem;
      padding-top: 2rem;

      font-size: rem(24px);
      line-height: 1.3;
    }

    .winner {
      margin-bottom: 1.5rem;

      font-size: rem(24px);
    }

    .arrow {
      bottom: 5rem;
      left: calc(50% - 23rem);

      width: 13rem;

      &.arrow2 {
        right: calc(50% - 22rem);
        bottom: 5rem;
        left: auto;

        width: 13rem;
      }
    }
  }
  .ruffle {
    padding-top: 2.5rem;

    .container {
      max-width: 54rem;
    }

    &__title {
      position: relative;

      width: 50rem;
      height: 18rem !important;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }

    &__step {
      max-width: 37rem;
      margin: 0 auto 2.6rem;

      font-size: rem(24px);

      p {
        margin-top: -0.6rem;

        line-height: 1.3;

        span {
          font-weight: 700;
        }
      }
    }

    .hash-tag {
      font-size: rem(120px);
      text-align: center;
    }

    .hash-tag1 {
      margin-top: -0.4rem;
      padding-right: 3.7rem;
    }

    .hash-tag2 {
      margin: -1rem 0;
      padding-left: 4rem;
    }

    .container > p {
      margin: 3.8rem -1rem 0;

      font-size: rem(28px);
      line-height: rem(36px);
      text-align: center;

    }

    &__rules {
      width: 21rem;
      margin: 2.5rem auto 11rem;

      img {
        width: 100%;
      }
    }
  }
  .try {
    padding-bottom: 1.5rem;

    .container {
      max-width: 54rem;
    }

    &__title {
      margin: 8.7rem auto 1rem;
    }

    &__content {
      &:before {
        top: -8.5rem;
        left: 50%;

        width: 88rem;
        height: 51rem;

        background-image: url("@/assets/img/try/bg_d.svg");
        background-size: contain;
      }
    }

    &__icons {
      width: 43rem;
      margin: 3.2rem auto 26px;

      img {
        height: 11.9rem;
      }
    }

    p {
      margin: 3rem auto 7rem;

      font-size: rem(24px);
      line-height: rem(32px);
    }

    .button {
      width: 21rem;
      margin: 2rem auto;

      cursor: pointer;
    }
  }
  footer {
    padding: 1.5rem;

    border-radius: rem(17px) rem(17px) 0 0;

    .container {
      max-width: 78.3rem;
    }

    a.rules-link {
      font-size: rem(12px);
    }

    p {
      width: 26rem;
      margin-top: 0.6rem;

      font-size: rem(12px);
    }

    .social {
      margin-top: 1.9rem;
      margin-bottom: -2.4rem;

      a {
        margin-right: -0.8rem;
        margin-left: 1rem;

        transition: 0.2s;

        &:hover {
          filter: drop-shadow(0px 0px 16px $filterColor) drop-shadow(0px 0px 4px $filterColor) drop-shadow(0px 0px 8px $filterColor) drop-shadow(0px 0px 24px $filterColor);
        }
      }
    }
  }
  .modal {
    &__scroll {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__content {
      max-width: 60rem;
      margin: 0 auto;
      padding: rem(80px) 0;

      background-image: url("@/assets/img/modal/bg_d.png");
      background-position: center center;
      background-size: contain;

      p {
        width: 100%;
        margin-top: 0.2rem;

        font-size: rem(24px);
        line-height: 1.3;

        &:nth-of-type(2) {
          width: 100%;
          margin-top: 0.7rem;
        }
      }

      /*&:before {
        top: -5.4rem;
        left: 50%;

        width: 60rem;
        height: 50rem;
      }*/
    }

    &__closer {
      top: calc(50% - 24rem);
      right: calc(50% - 25.4rem);

      cursor: pointer;
      filter: drop-shadow(0px 0px 16px #00D5FF) drop-shadow(0px 0px 4px #00D5FF) drop-shadow(0px 0px 8px #00D5FF) drop-shadow(0px 0px 24px #00D5FF);
      transition: 0.2s;

      &:hover {
        filter: drop-shadow(0px 0px 16px #00D5FF) drop-shadow(0px 0px 24px #00D5FF);
      }
    }

    &__title {
      width: 28rem;
      margin-top: 0;
    }

    &__button {
      width: 19rem;
      margin: 1.4rem auto;

      cursor: pointer;
    }

    a {
      width: fit-content;
      margin-top: 0;

      font-size: rem(18px);
    }
  }
  .twister-title {
    width: 100%;
    height: 7rem;

    &.fz48 {
      height: auto;

      p {
        font-size: rem(90px) !important;
        line-height: rem(72px) !important;
      }
    }

    p {
      position: absolute;
      top: 0;
      left: 50%;

      width: 100%;
      margin-bottom: 0;
      padding: 0;

      font-family: DearType;
      font-size: rem(120px) !important;
      line-height: rem(104px) !important;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: rem(5px);

      transform: translateX(-50%) rotate(-2.1deg);
    }

    img {
      width: 100%;
    }
  }
}

@media screen and (min-width: 1000px) and (max-width: 1550px) {
  .chips__chip {
    transform: scale3d(0.7, 0.7, 1) !important;

    &.chip3 {
      transform-origin: right center;
    }
  }
  #app {
    .gradients {
      .gradient1 {
        margin-bottom: -110rem;
      }
    }

    .chips {
      .chip10 {
        top: 140.4rem;
        left: calc(50% - 62rem);
      }

      .chip11 {
        display: none;
      }
    }

    .gifts {
      .gift4 {
        top: 214rem;
      }
    }
  }
}

@media screen and (min-width: 1000px) and (max-width: 1300px) {
  .chips__chip {
    transform: scale3d(0.6, 0.6, 1) !important;
  }

  #app {
    .chips {
      .chip10 {
        left: calc(50% - 55rem);
      }
    }
  }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
  .chips__chip {
    transform: scale3d(0.5, 0.5, 1) !important;
  }

  #app {
    .gradients {
      .gradient2 {
        margin-top: -15rem;
      }
    }

    .chips {
      .chip11 {
        left: calc(50% - 87rem);
      }

      .chip20 {
        display: none;
      }
    }

    .gifts {
      .gift4 {
        right: 4.2rem;
      }

      .gift5 {
        left: 1.8rem;
      }

      .gift7 {
        right: -3rem;
      }

      .gift8 {
        left: -8rem;
      }

      .gift9 {
        display: none;
      }
    }
  }
  .ruffle .container > p, .ruffle__step p, .intro p {
    text-shadow: 0 0 18px black;
  }
  .mix__result .float:nth-of-type(1) {
    bottom: 7rem;
    left: -2.5rem;

    width: 28rem;
  }
  .mix__result .float:nth-of-type(2) {
    right: -2.5rem;
    bottom: 7rem;

    width: 28rem;
  }
  .mix__result .float:nth-of-type(3) {
    left: calc(50% - 21rem);

    width: 20rem;
  }
  .mix__result .float:nth-of-type(4) {
    right: calc(50% - 19rem);

    width: 20rem;
  }
  .mix .result-container .mix__next {
    width: 18rem;

    &.first {
      margin-top: 34.3rem;
    }
  }
}

@media screen and (min-width: 2030px) {
  .mix .swiper {
    &:before, &:after {
      display: none;
    }

    .swiper-slide {
      &:not(.swiper-slide-active) {
        filter: brightness(0.3);
      }

      &.swiper-slide-next, &.swiper-slide-prev {
        filter: brightness(0.8);
      }
    }
  }
}
@media screen and (min-width: 1001px) and (max-width: 1300px){
  #app {
    .chips {
      .chip15 {
        top: 288.4rem;
        left: calc(50% - 59rem);
      }
      .chip16 {
        display: none;
      }
      .chip17 {
        display: none;
      }
      .chip20 {
        top: 329.7rem;
        right: -27rem;
      }
    }
    .gifts {
      .gift7 {
        right: 1rem;
      }
    }
  }
  .modal {
    &__content {
      padding: 3.5rem 0;
    }
    &__closer {
      top: calc(50% - 20rem);
    }
  }
}

